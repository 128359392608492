module.exports = new Promise(resolve => {
    const script = document.createElement('script');
    const meta = document.querySelector('meta[name="widen:env"]');
    const env = meta?.content ?? window.env;

    script.src = env === 'stage' ? 'https://static.widencdn-stage.net/einstein@2/remoteEntry.js' : 'https://static.widencdn.net/einstein@2/remoteEntry.js';
    script.onload = () => {
      resolve({
        get: window.einstein.get,
        init: window.einstein.init
      })
    };

    document.head.appendChild(script);
  });